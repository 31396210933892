import { useCallback, useEffect, useState } from "react";

const OrderForm = ({}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [phone, setPhone] = useState(false);
  const [email, setEmail] = useState(false);
  const [name, setName] = useState(false);
  const reg = new RegExp("^[0-9]+$");
  const onPhone = (e) => {
    let text = e.target.value;
    if (!reg.test(text)) {
      e.target.value = e.target.value.slice(0, -1);
      return;
    }
    setPhone(() => e.target.value);
  };


  return (
    <div className="col-12 d-flex flex-column align-items-center">
      <h1 className="fs-3 text-muted">שניה לפני שממשיכים...</h1>
      <div className="col-12 d-flex flex-column align-items-center">
        <div className="mb-3 col-xxl-6 col-xl-6 col-lg-8 col-md-10 col-sm-10 col-10 d-flex flex-row-reverse">
          <input
            type="text"
            className="form-control"
            placeholder="הקלד כאן"
            aria-label="הקלד כאן"
            aria-describedby="basic-addon2"
            required
            id="name"

            onInput={(e) => setName(e.target.value)}
          />
          <span
            className="input-group-text col-xxl-2 col-xl-2 col-md-3 col-lg-3 col-sm-4 col-4"
            id="basic-addon2"
          >
            שם מלא*
          </span>
        </div>
        <div className="mb-3 col-xxl-6 col-xl-6 col-lg-8 col-md-10 col-sm-10 col-10 d-flex flex-row-reverse">
          <input
            type="text"
            className="form-control"
            placeholder="הקלד כאן"
            aria-label="הקלד כאן"
            aria-describedby="basic-addon2"
            required
            id="phone"
            onInput={(e) => onPhone(e)}
          />
          <span
            className="input-group-text col-xxl-2 col-xl-2 col-md-3 col-lg-3 col-sm-4 col-4"
            id="basic-addon2"
          >
            טלפון*
          </span>
        </div>
        <div className="mb-3 col-xxl-6 col-xl-6 col-lg-8 col-md-10 col-sm-10 col-10 d-flex flex-row-reverse">
          <input
            type="email"
            className="form-control"
            placeholder="הקלד כאן"
            aria-label="הקלד כאן"
            aria-describedby="basic-addon2"
            pattern=".+@.+"
            onInput={(e) => setEmail(e.target.value)}
            id="email"

          />
          <span
            className="input-group-text col-xxl-2 col-xl-2 col-md-3 col-lg-3 col-sm-4 col-4"
            id="basic-addon2"
          >
            אימייל
          </span>
        </div>
      </div>
      <div className="col-8 d-flex justify-content-center align-content-center">
        <span className="" id="resultText">לאחר יצירת ההזמנה אנו ניצור עמך קשר.</span>
      </div>
    </div>
  );
};

export default OrderForm;
