import { useCallback, useEffect, useState } from "react";
import OrderForm from "../Form";
import "./Cart.css";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import {setLangauge} from "../../slice"
const Results = ({ productsInCart, addOneMore, removeProduct }) => {
  if (productsInCart.length == 0) {
    return "אין מוצרים בעגלה.";
  }
  return productsInCart.map((el) => (
    <Item
      name={el.name}
      price={el.price}
      quantity={el.quantity}
      addOneMore={addOneMore}
      key={el.name}
      removeProduct={removeProduct}
    />
  ));
};
const Item = ({ price, name, quantity, addOneMore, removeProduct }) => {
  return (
    <div className="col-12 d-flex flex-row flex-wrap align-items-center justify-content-evenly border-bottom m-2 ">
      <div className="col-xxl-1 col-xl-1 col-lg-1 col-md-1 col-sm-5 col-5 border-xxl-start border-xl-start border-lg-start border-md-start border-sm-center border-center d-flexalign-items-center justify-content-center">
        <img src="/assets/parts.svg" height="25" width="25" />
      </div>
      {/* Description */}
      <span className="col-xxl-6 col-xl-6  col-lg-5 col-md-8 col-sm-0 col-10 d-flex flex-row align-items-center justify-content-center text-muted flex-wrap">
        <span className="m-1 fw-bolder">{name}</span>
        <span className="m-1 fw-bolder col-xxl-1 col-xl-1 col-lg-1 col-md-1 col-sm-1 col-5">
          {price}₪
        </span>
      </span>
      {/* controller */}
      <span className="col-xxl-3 col-xl-3  col-lg-5 col-md-8 col-sm-10 col-10 d-flex flex-row flex-wrap align-items-center justify-content-center">
        <a
          className="m-1 pointer"
          onClick={() => addOneMore({ name: name, action: "add" })}
        >
          <img src="/assets/up.svg" height="25" width="25" />
        </a>
        <span className="m-1 fs-5">{quantity}</span>
        <a
          className="m-1 pointer"
          onClick={() =>
            quantity >= 1
              ? addOneMore({ name: name, action: "less" })
              : () => {}
          }
        >
          <img
            src="/assets/up.svg"
            height="25"
            style={{ transform: "rotate(180deg)" }}
            width="25"
          />
        </a>
        <span
          className="m-1 col-xxl-auto col-xl-auto  col-lg-auto col-md-auto col-sm-12 col-12 pointer rounded ps-1 pe-1"
          onClick={() => removeProduct(name)}
        >
          <img src="/assets/trash.svg" height="18" width="18" />
        </span>
      </span>
    </div>
  );
};
const Cart = ({
  addProduct,
  productsInCart,
  addOneMore,
  removeProduct,
  setShowContact,
}) => {
  const count = useSelector((state) => state);
  let lang = useSelector((state) => state.products.lang);

  console.log(count);
  const [isOpen, setIsOpen] = useState(false);
  const [total, setTotal] = useState(0);
  const [totalProducts, setTotalProducts] = useState(0);
  const [isNext, setIsNext] = useState(false);
  const [sendForm, setSendForm] = useState(false);
  const [clientData, setClientData] = useState({});
  const disptach = useDispatch();
  const sendInv = () => {
    let email = document.querySelector("#email").value;
    let phone = document.querySelector("#phone").value;
    let name = document.querySelector("#name").value;
    let resultText = document.querySelector("#resultText");
    let submitBtn = document.querySelector("#submitBtn");

    setClientData({ email, phone, name });

    if (!email || !phone || !name) {
      resultText.textContent = "יש להזין את שדות החובה.";
    } else {
      resultText.textContent = "ההזמנה התקבלה ואנו ניצור עמך קשר.";
      submitBtn.disabled = true;
      submitBtn.textContent = "ההזמנה בוצעה";

      setSendForm((prev) => !prev);
    }
  };
  useEffect(async () => {
    if (sendForm) {
      let res = await axios.post("https://harelserver778.herokuapp.com/walla", {
        name: " from app",
        phone: "0522813907",
        data: { clientData, productsInCart, total },
      });
      // setIsNext(false)
    }
  }, [sendForm]);

  useEffect(() => {
    setIsNext(false);
    if (isOpen) {
      document.querySelector(".cartContainer").classList.remove("d-none");
      return;
    }
    document.querySelector(".cartContainer").classList.add("d-none");
    if (!isNext) {
      let submitBtn = document.querySelector("#submitBtn");
      submitBtn.disabled = false;
      submitBtn.textContent = "המשך";
    }
  }, [isOpen]);

  useEffect(() => {
    let total = 0;
    let totalProducts = 0;
    productsInCart.forEach((el) => {
      let int = parseFloat(el.price);
      if (el.quantity > 1) {
        let smallTotal = int * el.quantity;
        total += smallTotal;
        totalProducts += el.quantity;
      }
      if (el.quantity == 1) {
        total += int;
        totalProducts += el.quantity;
      }
    });
    setTotalProducts(totalProducts);
    setTotal(total);
  }, [productsInCart]);
  return (
    <div className="col-12">
      <div
        className="bg-white position-absolute cartOpen rounded cartContainer border shadow-lg d-none"
        style={{ zIndex: "9999", minHeight: "750px", maxHeight: "850px" }}
      >
        <div
          className="col-auto position-absolute start-0 p-2 m-2 fs-3 pointer"
          onClick={() => setIsOpen((prev) => !prev)}
        >
          X
        </div>
        <div className="col-xxl-3 col-xl-3 col-lg-8 col-md-8 col-sm-11 col-11 logoContainer d-flex justify-content-center align-items-center align-content-center">
          <img src="/assets/logo.svg" />
        </div>
        <div className="col-12 d-flex flex-column align-items-center">
          <h1 className="mt-3 fs-3 textblue">המוצרים שלך</h1>
          <hr
            className="bg-dark m-auto text-muted shadow-sm"
            style={{ width: "90%" }}
          />
          <div
            className={`col-11 bg-light rounded shadow-sm p-2 d-flex flex-column align-items-center justify-content-${
              productsInCart.length > 0 ? "start" : "center"
            } mt-3 itemContainer text-center`}
            style={{
              maxHeight: "50vh",
              overflowY: "auto",
              overflowX: "hidden",
              minHeight: "45vh",
            }}
          >
            {isNext ? (
              <OrderForm />
            ) : (
              <Results
                productsInCart={productsInCart}
                addOneMore={addOneMore}
                removeProduct={removeProduct}
              />
            )}
          </div>
          <div className="col-11 rounded mb-5 mt-3 shadow-sm bg-light d-flex justify-content-center flex-column align-items-center">
            <h1 className="textblue fs-3 pt-1">סה"כ</h1>
            <hr
              className="m-2 text-muted text-dark bg-dark"
              style={{ width: "60%" }}
            />
            <div
              className="col-6 border border-bottom rounded rounded-5 text-center d-flex flex-column align-items-center justify-content-center"
              style={{ minHeight: "50px" }}
            >
              <span className="fw-bold text-muted fs-5">{total}₪</span>
            </div>
            <div className="d-grid col-6 mt-2">
              <button
                id="submitBtn"
                role="button"
                onClick={isNext ? () => sendInv() : () => setIsNext(true)}
                disabled={productsInCart.length > 0 ? false : true}
                className="btn btn-sm btn-primary p-2"
              >
                {isNext ? "שלח הזמנה" : "המשך"}
              </button>
            </div>
            <div className="d-grid col-3 m-1">
              <div
                className="btn btn-sm bg-white text-primary border-primary p-0 continueBtn"
                onClick={() => setIsOpen((prev) => !prev)}
              >
                המשך ברכישה
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="col-12 position-relative d-flex flex-row justify-content-xxl-end justify-content-xl-end justify-content-lg-between justify-content-md-center  justify-content-sm-end  justify-content-end align-items-center align-content-center roundedCart ms-5 ">
        <div className="col-xxl-1 col-xl-1 col-lg-2 col-md-2 col-sm-3 col-3 d-flex flex-row me-2 position-absolute end-0">
          <select className="form-select col-3" id="langSelect"onChange={(e)=>disptach(setLangauge(e.target.value))}>
            <option selected={lang === "he" ? true : false} value="he" >עברית</option>
            <option selected={lang === "ar" ? true : false} value="ar">عربى</option>
          </select>
        </div>
        <div className="col-6 d-flex flex-row justify-content-xxl-end justify-content-xl-end justify-content-lg-end justify-content-md-center  justify-content-sm-center  justify-content-center align-items-center align-content-center roundedCart ms-5 ">
          <div
            className="cartBtn m-1 col-auto  d-flex justify-content-center align-items-center border-start pointer rounded p-2 position-relative"
            onClick={() => setIsOpen((prev) => !prev)}
          >
            <img src="/assets/cart.svg" height="35" width="35" />
            <span
              className="d-flex justify-content-center align-items-center border border-primary border-2 position-absolute end-0 top-0 p-2 rounded-circle"
              style={{ height: "20px", width: "20px" }}
            >
              <span
                className="p-2 fw-bold text-dark"
                style={{ fontSize: "12px" }}
              >
                {totalProducts}
              </span>
            </span>
            <span className="">העגלה שלך</span>
          </div>
          <div
            className=" cartBtn m-1 col-auto  d-flex justify-content-center align-items-center pointer rounded"
            onClick={setShowContact}
          >
            <img src="/assets/phone.svg" height="35" width="35" />
            <span className="p-3">צור קשר</span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Cart;
