import { useEffect, useState } from "react";
import "./App.css";
import Cart from "./components/Cart";
import Nav from "./components/Nav";
import AppRouter from "./Router";
import { useSelector, useDispatch } from "react-redux";
import { setProducts, setProductsFromSearch } from "./slice";
import $ from "jquery";
import Contact from "./components/Contact";
import axios from "axios";

function App() {
  let lang = useSelector((state) => state.products.lang);
  const [productSource, setProductSource] = useState([]);
  let elem = useSelector((state) => state.products.prods);
  const [updatedLang, setUpdatedLang] = useState(false);
  const [currentLang, setCrrentLang] = useState("he");

  const [searchResult, setSearchResult] = useState([]);
  const editSearchResul = (value) => {
    setSearchResult(() => value);
    return;
  };
  const updateSearchResult = (value) => {
    setSearchResult(value);
    return;
  };
  const disptach = useDispatch();
  const [productsInCart, setProductsInCart] = useState(elem);
  const [showContact, setShowContact] = useState(false);
  const googleTranslateElementInit = () => {
    new window.google.translate.TranslateElement(
      {
        pageLanguage: "he",
        includedLanguages: "ar",
        autoDisplay: false,
        layout: window.google.translate.TranslateElement.FloatPosition.TOP_LEFT,
      },
      "google_translate_element"
    );
  };

  useEffect(async () => {
    console.log("lang", lang);
    let item = sessionStorage.getItem("data");
    if (!item) {
      const url = `https://harelserver778.herokuapp.com/?lang=${lang}`;
      const req = await axios.get(url);
      console.log("walla", req.data);
      window.sessionStorage.setItem("data", JSON.stringify(req.data));
      setProductSource(req.data);
      setCrrentLang(lang);
      return;
    }
    if (lang != currentLang) {
      const url = `https://harelserver778.herokuapp.com/?lang=${lang}`;
      const req = await axios.get(url);
      console.log("walla", req.data);
      window.sessionStorage.setItem("data", JSON.stringify(req.data));
      setProductSource(req.data);
      setCrrentLang(lang);
      return;
    }
    let parsed = JSON.parse(item);
    setProductSource(parsed);
    setCrrentLang(lang);

    return;
  }, [lang]);

  useEffect(() => {
    disptach(setProducts(productsInCart));
  }, [productsInCart]);

  useEffect(() => {
    // disptach(setProductsFromSearch(searchResult));
    console.log("searchResultPersist", searchResult);
  }, [searchResult]);

  const removeProduct = (name) => {
    let res = productsInCart.filter((el) => el.name !== name);
    setProductsInCart(() => res);
    return;
  };
  const setContactShow = () => {
    setShowContact((prev) => !prev);
  };
  const addOneMore = ({ name, action }) => {
    const copy = JSON.parse(JSON.stringify(productsInCart));
    const mapped = copy.map((el) => {
      if (el.name === name) {
        let parsed = parseInt(el.quantity);

        if (action === "add") {
          el["quantity"] = parsed + 1;
        }
        if (action === "less") {
          el["quantity"] = parsed - 1;
        }
      }
      return el;
    });
    setProductsInCart(mapped);
  };
  const addProduct = (item) => {
    let spinner = document.querySelector(".spinner-grow");
    spinner.classList.remove("d-none");
    setTimeout(() => {
      spinner.classList.add("d-none");
    }, 500);
    let itemCombined = {
      price: item.price,
      name: item.productName,
      quantity: 1,
    };
    let itemExist = productsInCart.filter(
      (el) => el.name === itemCombined.name
    );
    if (itemExist == false) {
      setProductsInCart((prev) => [...prev, itemCombined]);
      return;
    }
    let { quantity } = itemExist[0];
    let newPayload = {
      name: itemCombined.name,
      price: itemCombined.price,
      quantity: quantity + itemCombined.quantity,
    };
    let filteredAll = productsInCart.filter(
      (el) => el.name !== itemCombined.name
    );
    let newArr = [].concat(filteredAll, newPayload);
    setProductsInCart(newArr);
  };

  return (
    <div className="App position-relative">
      <Nav
        productsInCart={productsInCart}
        addProduct={addProduct}
        updateSearchResult={updateSearchResult}
        productSource={productSource}
      />

      <Contact show={showContact} setShowContact={setContactShow} />
      <div className="col-xxl-1 col-xl-2 col-lg-3 col-md-3 col-sm-5 col-5 bg-light shadow-sm infoContainer text-center text-white d-flex justify-content-center align-items-center rounded spinner-grow d-none">
        <img src="/assets/check.svg" height="75" width="75" />
      </div>
      <Cart
        productsInCart={productsInCart}
        addProduct={addProduct}
        addOneMore={addOneMore}
        removeProduct={removeProduct}
        setShowContact={setContactShow}
      />
      <AppRouter
        productsInCart={productsInCart}
        addProduct={addProduct}
        updateSearchResult={updateSearchResult}
        productSource={productSource}
      />
    </div>
  );
}

export default App;
