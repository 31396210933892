import axios from "axios";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
const SearchPage = ({ addProduct }) => {
  let searchResults =
    useSelector((state) => state.products.productsFromSearch) || [];
  const { term } = useParams();
  console.log("term", term);
  const [pageHeader, setPageHeader] = useState("");
  const [icon, setIcon] = useState("");
  const [color, setColor] = useState("purple");
  const [productName, setproductName] = useState("");
  const [price, setPrice] = useState("");
  const [subCat, setSubCat] = useState([]);
  const [cat, setCat] = useState([]);
  const [dropSubCat, setDropSubCat] = useState([]);
  const [isMainCat, setIsMainCat] = useState("");
  const [localData, setLocalData] = useState([]);
  const [itemKey, setItemKey] = useState("");
  const [isImage, setIsImage] = useState(true);
  let lang = useSelector((state) => state.products.lang);

  useEffect(() => {}, []);

  useEffect(async () => {
    if (term) {
      let res = await axios.post(`https://harelserver778.herokuapp.com/search?lang=${lang}`, {
        term: term,
      });
      console.log(res.data);
      setLocalData(res.data);
    }
    if (!term) {
      setLocalData(searchResults);
    }
  }, [term, searchResults]);

  const handleClick = (e, name, price, mainCat, subCut, itemkey) => {
    e.preventDefault();
    setproductName(name);
    setPrice(price);
    setCat(mainCat);
    setSubCat(subCut);
    setItemKey(itemkey);

    window.$("#staticBackdropCustom").modal("show");
  };
  return (
    <div className="col-xxl-8 col-xl-8 col-lg-8 col-md-10 col-12 col-sm-12 d-flex flex-column justify-content-start mt-2 align-items-center m-auto position-relative">
      <div
        class="modal fade"
        id="staticBackdropCustom"
        data-bs-backdrop="static"
        data-bs-keyboard="false"
        tabindex="-1"
        aria-labelledby="staticBackdropLabel"
        aria-hidden="true"
        style={{ zIndex: 9992 }}
      >
        <div class="modal-dialog">
          <div class="modal-content">
            <div class="modal-header">
              <button
                type="button"
                class="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div class="modal-body d-flex flex-column">
              <div className="fs-3">{productName}</div>
              <div className="col-12 d-flex justify-content-center align-items-center">
                  <img
                    src={encodeURI(`https://bucketeer-b1012d45-3216-4739-85bc-6483e7e00523.s3.eu-west-1.amazonaws.com/${itemKey}.png`)}
                    alt=""
                    className="m-2"
                    height="200"
                    width="200"
                    onError={(e) => window.$(e.target).hide()}
                    onLoad={(e) => {
                      window.$(e.target).show();
                      return encodeURI(`https://bucketeer-b1012d45-3216-4739-85bc-6483e7e00523.s3.eu-west-1.amazonaws.com/${itemKey}.png`);
                    }}
                  />
              </div>
              <hr className={`${color}`} />
              <div className="fs-3">₪{price}</div>
              <hr className="col-6 m-auto " />
              <div
                className="d-flex flex-row justify-content-center align-items-center mt-1 pointer"
                onClick={() => addProduct({ price, productName })}
              >
                <span className="">הוסף לעגלה</span>
                <img
                  src={`/assets/add.svg`}
                  className="m-1"
                  height="25"
                  width="25"
                />
              </div>
            </div>

            <div class="modal-footer d-flex justify-content-center flex-column">
              <div className="col-6 d-flex justify-content-center flex-column">
                תוכלו למצוא עוד מוצרים דומים ב:
                <a className="" href={`/products/${cat[1]}?color=${color}`}>
                  {cat[0]}
                </a>
                <a className="" href={`/products/${subCat[1]}?color=${color}`}>
                  {subCat[0]}
                </a>
              </div>
              <button
                type="button"
                class={`btn ${color} text-white`}
                data-bs-dismiss="modal"
              >
                סגור
              </button>
            </div>
          </div>
        </div>
      </div>
      <h1 className="textbeliz">תוצאות חיפוש</h1>
      <div className="col-12 d-flex flex-row justify-content-center align-items-center flex-wrap">
        {localData.map((el) => {
          console.log(el.ItemKey)
          return (
            <a
              className={`col-xxl-2 col-xl-2 col-lg-4 col-md-4 col-sm-5  col-5 m-1  d-flex flex-column singleProduct hover${color} p-2 pointer position-relative text-dark`}
              data-bs-toggle="modal"
              data-bs-target="#staticBackdropCustom"
              onClick={(e) =>
                handleClick(
                  e,
                  el.Description,
                  el.Price,
                  [el.MainCategory, el.MainCat],
                  [el.SubCategory, el.SubCat],
                  el.ItemKey
                )
              }
            >
              <span className="text-muted fw-bold col-12 d-flex flex-column justify-content-center align-items-center">
                  <span style={{fontSize:"13px"}}>{el.Description}</span>

                </span>
                <img
                    src={encodeURI(`https://bucketeer-b1012d45-3216-4739-85bc-6483e7e00523.s3.eu-west-1.amazonaws.com/${el.ItemKey}.png`)}
                    alt=""
                    className="mt-1 mb-1 align-self-center"
                    height="50"
                    width="50"
                    onError={(e) => window.$(e.target).hide()}
                    onLoad={(e) => {
                      window.$(e.target).show();
                      return encodeURI(`https://bucketeer-b1012d45-3216-4739-85bc-6483e7e00523.s3.eu-west-1.amazonaws.com/${el.ItemKey}.png`);
                    }}
                  />
              <div className="d-flex flex-column justify-content-center priceBar">
                <span className={`border-bottom border${color}`}>
                  {el.MainCategory}
                </span>
                <span className={`border-bottom border${color}`}>
                  {el.SubCategory}
                </span>
                <span className={`col-12 text-white  m-0 p-0  ${color}`}>
                  ₪{el.Price}
                </span>
              </div>
            </a>
          );
        })}
      </div>
    </div>
  );
};
export default SearchPage;
