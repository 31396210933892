import { configureStore,combineReducers,com } from '@reduxjs/toolkit'
import { persistStore, persistReducer,persistCombineReducers } from 'redux-persist'
import storageSession from 'redux-persist/lib/storage/session'
 
import prodSlice from './slice'
 
const persistConfig = {
  key: 'root',
  storage:storageSession,
}
const rootReducer = combineReducers({
  products:prodSlice
})

const persistedReducer = persistReducer(persistConfig,rootReducer)
 
export default () => {
  let store = configureStore({
    reducer:persistedReducer
  })
  let persistor = persistStore(store)
  return { store, persistor }
}