import { useCallback, useEffect, useState } from "react";
import "./Contact.css";
const Contact = ({ show, setShowContact }) => {
  // const [isShow,setIsShow] = useState(false);
  // useEffect(()=>{
  //   setIsShow(show)
  // },[show])

  if (!show) {
    return null;
  }
  return (
    <div className="col-12 d-flex flex-column align-items-center bg-light shadow-lg contact">
      <div
        className="position-absolute top-0 start-0 fs-3 m-2 pointer"
        onClick={() => setShowContact()}
      >
        X
      </div>
      <h1 className="mt-5 text-muted">צרו איתנו קשר</h1>
      <a
        className="border border-2 rounded m-2 p-2 shadow-sm col-xxl-3 col-xl-3 col-lg-6 col-md-8 col-sm-10 col-10"
        href="tel:0775423651"
      >
        משרד - <a href="tel:0775423651">077-5423651</a>
      </a>
      <a
        className="border border-2 rounded m-2 p-2 shadow-sm col-xxl-3 col-xl-3 col-lg-6 col-md-8 col-sm-10 col-10"
        href="tel:0506826613"
      >
        כפיר - <a href="tel:0506826613">050-6826613</a>
      </a>
      <a
        className="border border-2 rounded m-2 p-2 shadow-sm col-xxl-3 col-xl-3 col-lg-6 col-md-8 col-sm-10 col-10"
        href="tel:0523854433"
      >
        שחר - <a href="tel:0523854433">052-3854433</a>
      </a>

      <a
        className="border border-2 rounded m-2 p-2 shadow-sm col-xxl-3 col-xl-3 col-lg-6 col-md-8 col-sm-10 col-10"
        target="_blank"
        href="https://api.whatsapp.com/send?text=&phone=0585423651"
      >
        וואטסאפ להזמנות -
        <a
          target="_blank"
          href="https://api.whatsapp.com/send?text=&phone=0585423651"
        >
          058-5423651
        </a>
      </a>
      <hr className="col-10" />
      <div className="border d-flex flex-column border-2 rounded m-2 col-xxl-6 col-xl-6 col-lg-6 col-md-8 col-sm-10 col-10 shadow-sm p-2">
        <span> כתובת המשרד:</span>
        <span>חרשי ברזל 4 - ירושלים</span>
        <span> כתובת למשלוח דואר : ת.ד 3414 ירושלים, 9103302</span>
        <span> ספק מורשה משרד הביטחון: 11015700</span>
        <span className="fw-bold">ניתן להיכנס עם הרכב עד לפתח המחסן</span>
      </div>
      <span className="fw-bold">שעות פתיחה:</span>
      <span>א-ה : 9:30-18:00</span>
      <span>ו : 9:30-12:30</span>
      <hr className="col-10" />
      <span>המכירה לחנויות ומעבדות בסיטונאות בלבד.</span>
      <h2 className="mt-2 text-muted">המיקום שלנו</h2>
      <iframe className="rounded m-2 shadow-sm col-11" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3392.8115663563644!2d35.213189420174096!3d31.7483439813864!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x1502d7e313c5e491%3A0xc352393e67ddb2a!2z15fXqNep15kg15HXqNeW15wgNCwg15nXqNeV16nXnNeZ150sIDkzNDIxOTI!5e0!3m2!1siw!2sil!4v1660034097573!5m2!1siw!2sil" width="400" height="300" style={{border:'2px solid grey'}} allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
      <span className="m-3" style={{opacity:"0.5"}}>Built By Amit Edri 052-2813907</span>
    </div>
  );
};

export default Contact;
