import { useCallback, useEffect, useState } from "react";
import Slider from "../../components/Slider";
import axios from "axios";
import "./CatPage.css";
import { useParams } from "react-router-dom";
import { useSelector } from "react-redux";
const setIconName = (origin) => {
  if (
    origin.includes("מסכ") ||
    origin.includes("מסכים") ||
    origin.includes("מסכי")
  ) {
    return ["ipad", "blue"];
  }
  if (
    origin.includes("חילוף") ||
    origin.includes("חלקי") ||
    origin.includes("רכיבים")
  ) {
    return ["parts", "orange"];
  }
  if (origin.includes("מכשירים")) {
    return ["iphoneDevice", "greeny"];
  }
  if (origin.includes("סוללות")) {
    return ["battery", "lightGreen"];
  }
  if (origin.includes("אוזני")) {
    return ["headphones", "yellow"];
  }
  if (origin.includes("שונות")) {
    return ["layers", "grey"];
  }
  if (origin.includes("JBL")) {
    return ["jbl", "lightGreen"];
  }
  if (origin.includes("מעבדה")) {
    return ["screwdriver", "pupral"];
  }
  if (origin.includes("כבלים")) {
    return ["usb", "midnight"];
  }
  if (origin.includes("כיסויים לטאבלטים")) {
    return ["cover", "wisteria"];
  }
  if (origin.includes("מגני מסך לטאבלטים")) {
    return ["ipadCover", "beliz"];
  }
  if (origin.includes("כיסויים")) {
    return ["touchscreen", "neprus"];
  }
  if (origin.includes("זיכרון")) {
    return ["memory", "weibo"];
  }
  if (origin.includes("מגני מסך")) {
    return ["touchscreen", "news"];
  }
  if (origin.includes("מטענים")) {
    return ["charger", "face"];
  }
  if (origin.includes("רכב")) {
    return ["car", "linked"];
  }
  if (origin.includes("מחשב")) {
    return ["computer", "vimeee"];
  } else {
    return ["layers", "grey"];
  }
};

const CatPage = ({ addProduct, productSource }) => {
  const [raw, setraw] = useState([]);
  const [pageHeader, setPageHeader] = useState("");
  const [icon, setIcon] = useState("");
  const [color, setColor] = useState("");
  const [productName, setproductName] = useState("");
  const [price, setPrice] = useState("");
  const [itemKey, setItemKey] = useState("");
  const [isImage, setIsImage] = useState(true);

  const [subCat, setSubCat] = useState([]);
  const [cat, setCat] = useState([]);
  const [dropSubCat, setDropSubCat] = useState([]);
  const [isMainCat, setIsMainCat] = useState("");
  const { id } = useParams();
  useEffect(async () => {
    const params = new URLSearchParams(window.location.search);
    const color = params.get("color");
    const icon = params.get("icon");
    setColor(color);
    setIcon(icon);
    setraw(productSource);
    return;
  }, [productSource]);
  useEffect(() => {
    raw.forEach((el) => {
      if (el.SubCat == id) {
        setPageHeader(() => el.SubCategory);
        // setCat([el.MainCategory, el.MainCat]);
        let item = setIconName(el.MainCategory);
        setColor(item[1]);
        return;
      }
      if (el.MainCat == id) {
        setIsMainCat(() => true);
        setPageHeader(() => el.MainCategory);
        // setSubCat([el.SubCategory, el.SubCat]);
        // setCat([el.MainCategory, el.MainCat]);
        let item = setIconName(el.MainCategory);
        setColor(item[1]);
        return;
      }
    });
  }, [raw]);
  useEffect(() => {
    let select = document.querySelectorAll("select");
    select.forEach((el) => {
      el.addEventListener("change", (e) => {
        window.location.pathname = `products/${e.target.value}`;
      });
    });

    return () => {
      let select = document.querySelectorAll("select");
      select.forEach((el) => {
        el.removeEventListener("change", (e) => {});
      });
    };
  }, [dropSubCat]);

  useEffect(() => {
    let subs = [];

    raw.forEach((el) => {
      if (el.MainCat == id) {
        let stringed = `${el.SubCategory}&${el.SubCat}`;

        if (!subs.includes(stringed)) {
          subs.push(stringed);
        }
      }
    });
    setDropSubCat(subs);
  }, [raw]);
  const handleClick = (e, name, price, mainCat, subCut, itemkey) => {
    e.preventDefault();
    setproductName(name);
    setPrice(price);
    setCat(mainCat);
    setSubCat(subCut);
    setItemKey(itemkey);

    window.$("#staticBackdrop").modal("show");
  };

  if (raw.length < 1) {
    return <div>Loading...</div>;
  }
  return (
    <div
      className="col-xxl-8 col-xl-8 col-lg-8 col-md-10 col-12 col-sm-12 d-flex flex-column justify-content-start mt-2 align-items-center m-auto position-relative"
      style={{ minHeight: "500px" }}
    >
      <div
        className="position-absolute end-0 pointer"
        onClick={() => window.history.back()}
      >
        <img
          src={`/assets/back.svg`}
          className="m-1"
          height="25"
          width="25"
          style={{ transform: "rotate(180deg)" }}
        />
      </div>

      <div
        class="modal fade"
        id="staticBackdrop"
        data-bs-backdrop="static"
        data-bs-keyboard="false"
        tabindex="-1"
        aria-labelledby="staticBackdropLabel"
        aria-hidden="true"
        style={{ zIndex: 9992 }}
      >
        <div class="modal-dialog">
          <div class="modal-content">
            <div class="modal-header">
              <button
                type="button"
                class="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div class="modal-body d-flex flex-column">
              <div className="fs-3">{productName}</div>
              <hr className={`${color}`} />
              <div className="col-12 d-flex justify-content-center align-items-center">
                  <img
                    src={encodeURI(`https://bucketeer-b1012d45-3216-4739-85bc-6483e7e00523.s3.eu-west-1.amazonaws.com/${itemKey}.png`)}
                    alt=""
                    className="m-2"
                    height="200"
                    width="200"
                    onError={(e) => window.$(e.target).hide()}
                    onLoad={(e) => {
                      window.$(e.target).show();
                      return encodeURI(`https://bucketeer-b1012d45-3216-4739-85bc-6483e7e00523.s3.eu-west-1.amazonaws.com/${itemKey}.png`);
                    }}
                  />
              </div>
              <div className="fs-3">₪{price}</div>
              <hr className="col-6 m-auto " />
              <div
                className="d-flex flex-row justify-content-center align-items-center mt-1 pointer"
                onClick={() => addProduct({ price, productName })}
              >
                <span className="">הוסף לעגלה</span>
                <img
                  src={`/assets/add.svg`}
                  className="m-1"
                  height="25"
                  width="25"
                />
              </div>
            </div>

            <div class="modal-footer d-flex justify-content-center flex-column">
              <div className="col-6 d-flex justify-content-center flex-column">
                תוכלו למצוא עוד מוצרים דומים ב:
                <a className="" href={`/products/${cat[1]}?color=${color}`}>
                  {cat[0]}
                </a>
                <a className="" href={`/products/${subCat[1]}?color=${color}`}>
                  {subCat[0]}
                </a>
              </div>
              <button
                type="button"
                class={`btn ${color} text-white`}
                data-bs-dismiss="modal"
              >
                סגור
              </button>
            </div>
          </div>
        </div>
      </div>
      <div className="d-flex flex-column justify-content-center align-items-center">
        <h1 className={`text${color}`}>{pageHeader}</h1>
        {isMainCat ? (
          <select className="form-select blueBorderLight text-muted shadow-sm">
            <option value="1">קטגוריה משנית</option>
            {dropSubCat.map((el) => {
              let splitted = el.split("&");
              let name = splitted[0];
              let num = splitted[1];
              return <option value={num}>{name}</option>;
            })}
          </select>
        ) : null}
      </div>
      <div className="col-12 d-flex flex-row justify-content-center align-items-center flex-wrap">
        {raw.map((el) => {
          
          if (el.SubCat == id || el.MainCat == id) {
            return (
              <a
                className={`col-xxl-2 col-xl-2 col-lg-4 col-md-4 col-sm-5  col-5 m-1  d-flex flex-column justify-content-between align-items-start singleProduct hover${color} pointer position-relative text-dark`}
                data-bs-toggle="modal"
                data-bs-target="#staticBackdrop"
                onClick={(e) =>
                  handleClick(
                    e,
                    el.Description,
                    el.Price,
                    [el.MainCategory, el.MainCat],
                    [el.SubCategory, el.SubCat],
                    el.ItemKey
                  )
                }
              >
                <span className="text-muted fw-bold col-12 d-flex flex-column justify-content-center align-items-center">
                  <span style={{fontSize:"13px"}}>{el.Description}</span>

                </span>
                <img
                    src={encodeURI(`https://bucketeer-b1012d45-3216-4739-85bc-6483e7e00523.s3.eu-west-1.amazonaws.com/${el.ItemKey}.png`)}
                    alt=""
                    className="mt-1 mb-1 align-self-center"
                    height="50"
                    width="50"
                    onError={(e) => window.$(e.target).hide()}
                    onLoad={(e) => {
                      window.$(e.target).show();
                      return encodeURI(`https://bucketeer-b1012d45-3216-4739-85bc-6483e7e00523.s3.eu-west-1.amazonaws.com/${el.ItemKey}.png`);
                    }}
                  />
                <div className="d-flex flex-column justify-content-center col-12">
                  <span className={`border-bottom border${color}`}>
                    {el.MainCategory}
                  </span>
                  <span className={`border-bottom border${color}`}>
                    {el.SubCategory}
                  </span>
                  <span className={`col-12  m-0 p-0  ${color}`}>
                    ₪{el.Price}
                  </span>
                </div>
              </a>
            );
          }
        })}
      </div>
    </div>
  );
};

export default CatPage;
