import axios from "axios";
import { useEffect, useState } from "react";
import readXlsxFile from "read-excel-file";
import { set_cptable, read, utils } from "xlsx";
import * as cptable from "xlsx/dist/cpexcel.full.mjs";
set_cptable(cptable);

const SalesReport = () => {
  const [profitReportFile, setProfitReportFile] = useState({});
  const [salesReportFile, setSalesReportFile] = useState({});
  const [budgetReport, setBudgetReport] = useState({});
  const [submit, setSubmit] = useState(false);

  useEffect(() => {
    if (submit) {
      
      const asyncReq = async () => {
        const form = new FormData();
        let budgetReportfilename = (budgetReport.filename = "budgetReport.xlsx");
        let salesReportFilefilename = (salesReportFile.filename = "salesReportFile.xlsx");
        let profitReportFilefilename = (profitReportFile.filename = "profitReportFile.xlsx");
        
        form.append("fileItems", budgetReport, budgetReportfilename);
        form.append("fileItems", salesReportFile, salesReportFilefilename);
        form.append("fileItems", profitReportFile, profitReportFilefilename);

        const req = await axios.post("/uploadReport", form, {
          responseType: "blob",
        });
        console.log(req.data);
        const href = URL.createObjectURL(req.data);

        // create "a" HTML element with href to file & click
        const link = document.createElement("a");
        link.href = href;
        link.setAttribute("download", "file.xlsx"); //or any other extension
        document.body.appendChild(link);
        link.click();
        console.log(req);
      };
      asyncReq();
    }
  }, [submit]);

  return (
    <div
      style={{ minHeight: "300px", backgroundColor: "#3498db" }}
      className="position-relative col-xxl-3 col-xl-5 col-lg-6 col-md-6 col-sm-10 col-10 d-flex flex-column justify-content-start align-items-center border m-2 rounded shadow-sm fs-3 fw-bolder text-white pointer mbtn"
    >
      <span className="">דוח רווחיות לקוחות</span>
      <form enctype="multipart/form-data" className="col-10">
        <div className="d-flex flex-column">
          <label for="fileName" class="form-label fs-6">
            לקוחות ותקציבים
          </label>
          <input class="form-control" placeholder=" לקוחות ותקציבים" type="file" id="formFile" fil onInput={(e) => setBudgetReport(e.target.files[0])} />
        </div>
        <div className="d-flex flex-column">
          <label for="fileName" class="form-label fs-6">
            דוח רווחיות לקוח
          </label>
          {/*  onInput={(e) => setFile(e.target.files[0])} */}
          <input class="form-control" placeholder=" דוח רווחיות לקוח" type="file" id="formFile" onInput={(e) => setProfitReportFile(e.target.files[0])} />
        </div>
        <div className="d-flex flex-column mt-2">
          <label for="fileName" class="form-label fs-6">
            דוח מכירות לקוחות
          </label>
          {/*  onInput={(e) => setFile(e.target.files[0])} */}
          <input class="form-control" placeholder=" דוח רווחיות לקוח" type="file" id="formFile" onInput={(e) => setSalesReportFile(e.target.files[0])} />
        </div>
        <div className="btn border border-white col-6 text-white shadow-sm mt-2" onClick={() => setSubmit((prev) => !prev)}>
          צור דוח
        </div>
      </form>
    </div>
  );
};

export default SalesReport;
