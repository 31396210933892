import { useEffect, useState } from "react";
import axios from "axios";
import "./Home.css";
const Home = ({ productSource }) => {
  const [raw, setraw] = useState([]);

  const [data, setData] = useState([]);

  useEffect(async () => {
    
    setraw(productSource);
  }, [productSource]);

  useEffect(() => {
    if (raw.length < 1) {
      return;
    }
    let mainCategories = [];

    raw.forEach((el) => {
      let mainCatPayload = `${el.MainCategory}&${el.MainCat}`;
      if (!mainCategories.includes(mainCatPayload)) {
        mainCategories.push(mainCatPayload);
      }
    });
    let res = {};
    for (let item of raw) {
      for (let main of mainCategories) {
        let mainSplitted = main.split("&");
        let catNum = mainSplitted[1];
        let catName = mainSplitted[0];

        if (item.MainCat == catNum) {
          if (!res.hasOwnProperty(catName)) {
            res[catName] = [];
          }
          let subCatPayload = `${item.SubCategory}&${item.SubCat}`;
          if (!res[catName].includes(subCatPayload)) {
            res[catName].push(subCatPayload);
          }
        }
      }
    }
    let result = Object.entries(res);
    console.log(result);
    setData(result);
  }, [raw]);
  const setIconName = (origin) => {
    if (
      origin.includes("מסכ") ||
      origin.includes("מסכים") ||
      origin.includes("מסכי")
    ) {
      return ["ipad", "blue"];
    }
    if (
      origin.includes("חילוף") ||
      origin.includes("חלקי") ||
      origin.includes("רכיבים")
      
    ) {
      return ["parts", "orange"];
    }
    if (origin.includes("מכשירים")) {
      return ["iphoneDevice", "greeny"];
    }
    if (origin.includes("סוללות")) {
      return ["battery", "lightGreen"];
    }
    if (origin.includes("אוזני")) {
      return ["headphones", "yellow"];
    }
    if (origin.includes("שונות")) {
      return ["layers", "grey"];
    }
    if (origin.includes("JBL")) {
      return ["jbl", "lightGreen"];
    }
    if (origin.includes("מעבדה")) {
      return ["screwdriver", "pupral"];
    }
    if (origin.includes("כבלים")) {
      return ["usb", "midnight"];
    }
    if (origin.includes("כיסויים לטאבלטים")) {
      return ["cover", "wisteria"];
    }
    if (origin.includes("מגני מסך לטאבלטים")) {
      return ["ipadCover", "beliz"];
    }
    if (origin.includes("כיסויים")) {
      return ["touchscreen", "neprus"];
    }
    if (origin.includes("זיכרון")) {
      return ["memory", "weibo"];
    }
    if (origin.includes("מגני מסך")) {
      return ["touchscreen", "news"];
    }
    if (origin.includes("מטענים")) {
      return ["charger", "face"];
    }
    if (origin.includes("רכב")) {
      return ["car", "linked"];
    }
    if (origin.includes("מחשב")) {
      return ["computer", "vimeee"];
    }
    return ["layers", "grey"];

  };
  if (raw.length < 1) {
    return <div>Loading...</div>;
  }
  return (
    <div className="d-flex flex-column justify-content-center align-items-center">
      {data.map((el) => {
        let [img, color] = setIconName(el[0]);
        return (
          <div
            className="col-12 d-flex flex-column justify-content-center border"
            style={{ minHeight: "150px" }}
          >
            <div className="col-12 d-flex flex-row justify-content-center align-items-center align-content-center position-relative">
              <img
                src={`/assets/${"legal5"}.png`}
                className="col-12 position-absolute top-0 h-100"
                style={{ opacity: 0.1 }}
              />
              <h1 className={`fs-2 m-2`} style={{ zIndex: 9996 }}>
                {el[0]}
              </h1>
              <img
                src={`/assets/${img}.svg`}
                className="m-2 btnImg"
                height="35"
                width="35"
                style={{ zIndex: 9993 }}
              />
            </div>

            <div className="d-flex flex-row flex-wrap justify-content-center align-items-center m-2 p-2">
              {el[1].map((el) => {
                let splitted = el.split("&");
                return (
                  <a
                    className={`text-muted col-xxl-1 col-xl-2 col-lg-2 col-md-3 col-sm-4 col-4 border m-2 shadow-sm d-flex flex-column align-items-center position-relative justify-content-center align-content-center hover${color}`}
                    style={{ height: "120px", width: "150px" }}
                    href={`/products/${splitted[1]}?color=${color}&icon=${img}`}
                  >
                    <span className="fs-5 p-1 col-12">{splitted[0]}</span>
                    {/* <hr className="m-0" style={{maxWidth:"90%"}}/> */}
                    <span
                      className={`${color} col-12 position-absolute bottom-0`}
                      style={{ height: "10px" }}
                    ></span>
                  </a>
                );
              })}
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default Home;
