import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Home from "../Pages/Home";
import CatPage from "../Pages/CatPage";
import Login from "../Pages/Login";

import { useCallback, useEffect, useState } from "react";
import SearchPage from "../Pages/SearchPage";
import { useSelector } from "react-redux";
import axios from "axios";

const Test = () => {
  let prods = useSelector((state) => state.products.prods);
  const [total, setTotal] = useState([]);
  useEffect(() => {
    const asyncReq = async () => {
      const url = `https://harelserver778.herokuapp.com/`;
      const req = await axios.get(url);
      console.log();
      const items = ["SC055", 101812];
      const total = [];
      for (let product of req.data) {
        for (let item of items) {
          if (product.ItemKey == item) {
            console.log(item);
            total.push(product);
          }
        }
      }
      setTotal(total);
    };
    asyncReq();
    // var element = document.getElementById("pdfTarget");
    // setTimeout(() => {
    //   const file = window.html2pdf(element, {});
    // console.log(file);
    // }, 2000);
  }, []);
  return (
    <div className="w-100 d-flex flex-column justify-content-start align-items-start d-block" id="pdfTarget">
      <img className="w-100" src="/assets/test.jpg"style={{height:"500px"}} />
      <div className="w-100 d-flex flex-row-reverse justify-content-center align-items-center" style={{minHeight:"500px"}}>
        <div className="col d-flex flex-column justify-content-start align-items-center bg-primary h-100">
          <span className="fw-bold col-7 text-end">פרמיום:</span>
          {total.map((el) => {
            return (
              <div className="w-100 d-flex flex-row justify-content-evenly align-items-center">
                <span className="fs-6">{el.Description}</span>
                <span className="fs-6">{el.Price}</span>
              </div>
            );
          })}
        </div>
        <div className="col d-flex flex-column justify-content-center align-items-center">
          <span className="bg-primary col-7 text-end">Banner</span>
        </div>
      </div>
    </div>
  );
};
const AppRouter = ({ productsInCart, addProduct, productSource }) => {
  const Backed = useCallback(() => <SearchPage addProduct={addProduct} />, []);
  return (
    <Router>
      <Routes>
        <Route exact path="/test" element={<Test />} />

        <Route exact path="/login" element={<Login addProduct={addProduct} />} />
        <Route exact path="/search/:term" element={<Backed />} />
        <Route exact path="/search" element={<Backed />} />

        <Route exact path="products/:id" element={<CatPage addProduct={addProduct} productSource={productSource} />} />
        <Route exact path="/" element={<Home addProduct={addProduct} productsInCart={productsInCart} productSource={productSource} />} />
        <Route path="*" element={<div>404</div>} />
      </Routes>
    </Router>
  );
};

export default AppRouter;
