import axios from "axios";
import { useCallback, useEffect, useState } from "react";
import "./Nav.css";
import { useDispatch } from "react-redux";
import { setProductsFromSearch } from "../../slice";
import storeMaker from "../../store";
import { useSelector } from "react-redux";
const setIconName = (origin) => {
  if (
    origin.includes("מסכ") ||
    origin.includes("מסכים") ||
    origin.includes("מסכי")
  ) {
    return ["ipad", "blue"];
  }
  if (
    origin.includes("חילוף") ||
    origin.includes("חלקי") ||
    origin.includes("רכיבים")
  ) {
    return ["parts", "orange"];
  }
  if (origin.includes("מכשירים")) {
    return ["iphoneDevice", "greeny"];
  }
  if (origin.includes("סוללות")) {
    return ["battery", "lightGreen"];
  }
  if (origin.includes("אוזני")) {
    return ["headphones", "yellow"];
  }
  if (origin.includes("שונות")) {
    return ["layers", "grey"];
  }
  if (origin.includes("JBL")) {
    return ["jbl", "lightGreen"];
  }
  if (origin.includes("מעבדה")) {
    return ["screwdriver", "pupral"];
  }
  if (origin.includes("כבלים")) {
    return ["usb", "midnight"];
  }
  if (origin.includes("כיסויים לטאבלטים")) {
    return ["cover", "wisteria"];
  }
  if (origin.includes("מגני מסך לטאבלטים")) {
    return ["ipadCover", "beliz"];
  }
  if (origin.includes("כיסויים")) {
    return ["touchscreen", "neprus"];
  }
  if (origin.includes("זיכרון")) {
    return ["memory", "weibo"];
  }
  if (origin.includes("מגני מסך")) {
    return ["touchscreen", "news"];
  }
  if (origin.includes("מטענים")) {
    return ["charger", "face"];
  }
  if (origin.includes("רכב")) {
    return ["car", "linked"];
  }
  if (origin.includes("מחשב")) {
    return ["computer", "vimeee"];
  } else {
    return ["layers", "grey"];
  }
};

const Nav = ({
  addProduct,
  productsInCart,
  setModalPayload,
  updateSearchResult,
  productSource,
}) => {
  const disptach = useDispatch();
  const [newData, setNewData] = useState([]);
  const [searchTerms, setSearchTerms] = useState("");
  const [raw, setraw] = useState([]);
  const [searchResults, setSearchResults] = useState([]);
  const [color, setColor] = useState("");
  const [doSearch, setDoSearch] = useState(false);
  const [productName, setproductName] = useState("");
  const [price, setPrice] = useState("");
  const [subCat, setSubCat] = useState([]);
  const [cat, setCat] = useState([]);
  const [itemKey, setItemKey] = useState("");
  const [isImage, setIsImage] = useState(true);
  const [moveToSearch, setMoveToSearch] = useState(true);
  let lang = useSelector((state) => state.products.lang);

  let [mainCategories, setMainCategories] = useState([]);
  let [subCategories, setSubCategories] = useState([]);
  useEffect(async () => {
    setraw(productSource);
  }, [productSource]);

  useEffect(() => {
    let searchResultsElm = document.querySelector(".searchResults");
    document.addEventListener("keypress", (e) => {
      if (e.key === "Enter" && !searchResultsElm.classList.contains("d-none")) {
        console.log(searchResults);
        // persistor.flush().then((res) => {
        //   window.location.pathname = "/search";
        // });
        let url = encodeURI(`${window.location.origin}/search/${searchTerms}`)
        window.location = url;
      }
    });
    return () => {
      document.removeEventListener("keypress", () => {});
    };
  }, [searchTerms]);
  useEffect(() => {
    if (doSearch) {
      let searchResultsElm = document.querySelector(".searchResults");
      if (!searchResultsElm.classList.contains("d-none")) {
        console.log(searchResults);

        window.location.pathname = `/search/${searchTerms}`;
      }
      setDoSearch(false);
    }
  }, [doSearch]);
  useEffect(() => {
    let mainCategories = [];
    let subCategories = [];
    raw.forEach((el) => {
      let mainCatPayload = `${el.MainCategory}&${el.MainCat}`;
      if (!mainCategories.includes(mainCatPayload)) {
        mainCategories.push(mainCatPayload);
      }
      let subCatPayload = `${el.SubCategory}&${el.SubCat}`;
      if (!subCategories.includes(subCatPayload)) {
        subCategories.push(subCatPayload);
      }
    });
    setSubCategories(subCategories);
    setMainCategories(mainCategories);
  }, [raw]);

  useEffect(() => {
    let select = document.querySelectorAll(".selectos");
    select.forEach((el) => {
      el.addEventListener("change", (e) => {
        window.location.href = `${window.location.origin}/products/${e.target.value}`;
      });
    });

    return () => {
      let select = document.querySelectorAll("select");
      select.forEach((el) => {
        el.removeEventListener("change", (e) => {});
      });
    };
  }, []);

  const resetSearch = (e) => {
    if (e.key === "Backspace") {
      setSearchResults([]);
    }
  };
  const handleClick = (e, name, price, mainCat, subCut, itemkey) => {
    e.preventDefault();
    setproductName(name);
    setPrice(price);
    setCat(mainCat);
    setSubCat(subCut);
    setSearchResults([]);
    setItemKey(itemkey);

    window.$("#staticBackdropItem").modal("show");
  };
  useEffect(async () => {
    let searchResultsElm = document.querySelector(".searchResults");

    if (searchTerms && searchTerms.length > 2) {
      let res = await axios.post(`https://harelserver778.herokuapp.com/search?lang=${lang}`, {
        term: searchTerms,
      });
      setSearchResults(() => res.data);

      if (res.data.length > 0) {
        searchResultsElm.classList.remove("d-none");
        disptach(setProductsFromSearch(res.data));
      }
    }
    return () => {};
  }, [searchTerms, lang]);
  const Results = ({ searchResults, onItemClick }) => {
    return searchResults.map((el) => {
      if (searchResults.length > 0) {
        let item = setIconName(el.MainCategory);
        return (
          <a
            // href={`products/${el.SubCat}?color=${item[1]}`}
            className={`p-2 border-top pointer text-dark d-block hoverSearch`}
            onClick={(e) =>
              onItemClick(
                e,
                el.Description,
                el.Price,
                [el.MainCat, el.MainCategory],
                [el.SubCat, el.SubCategory],
                el.ItemKey
              )
            }
          >
            {el.Description}
          </a>
        );
      }
    });
  };

  return (
    <div className="col-12 d-flex flex-row justify-content-xxl-between justify-content-xl-between justify-content-lg-center justify-content-md-center justify-content-sm-center justify-content-center align-items-between flex-wrap position-relative">
      <a
        href="/"
        className="col-xxl-3 col-xl-3 col-lg-8 col-md-8 col-sm-11 col-11 logoContainer d-flex justify-content-center align-items-center align-content-center"
      >
        <img src="/assets/logo.svg" />
      </a>
      <div
        class="modal fade"
        id="staticBackdropItem"
        data-bs-backdrop="static"
        data-bs-keyboard="false"
        aria-labelledby="staticBackdropLabel"
        aria-hidden="true"
        style={{ zIndex: 9998 }}
      >
        <div class="modal-dialog">
          <div class="modal-content">
            <div class="modal-header">
              <button
                type="button"
                class="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div class="modal-body d-flex flex-column">
              <div className="fs-3 pName">{productName}</div>
              <div className="col-12 d-flex justify-content-center align-items-center">
                  <img
                    src={encodeURI(`https://bucketeer-b1012d45-3216-4739-85bc-6483e7e00523.s3.eu-west-1.amazonaws.com/${itemKey}.png`)}
                    alt=""
                    className="m-2"
                    height="200"
                    width="200"
                    onError={(e) => window.$(e.target).hide()}
                    onLoad={(e) => {
                      window.$(e.target).show();
                      return encodeURI(`https://bucketeer-b1012d45-3216-4739-85bc-6483e7e00523.s3.eu-west-1.amazonaws.com/${itemKey}.png`);
                    }}
                  />
              </div>
              <hr className={`${color}` || "purple"} />
              <div className="fs-3 pPrice">₪{price}</div>
              <hr className="col-6 m-auto " />
              <div
                className="d-flex flex-row justify-content-center align-items-center mt-1 pointer"
                onClick={() => addProduct({ price, productName })}
              >
                <span className="">הוסף לעגלה</span>
                <img
                  src={`/assets/add.svg`}
                  className="m-1"
                  height="25"
                  width="25"
                />
              </div>
            </div>

            <div class="modal-footer d-flex justify-content-center flex-column">
              <div className="col-6 d-flex justify-content-center flex-column">
                תוכלו למצוא עוד מוצרים דומים ב:
                <a
                  className="pMain"
                  href={`/products/${cat[0]}?color=${"purple"}`}
                >
                  {cat[1]}
                </a>
                <a
                  className="pSub"
                  href={`/products/${subCat[0]}?color=${"purple"}`}
                >
                  {subCat[1]}
                </a>
              </div>
              <button
                type="button"
                class={`btn ${"purple"} text-white`}
                data-bs-dismiss="modal"
              >
                סגור
              </button>
            </div>
          </div>
        </div>
      </div>
      {/* Search */}
      <div
        className="col-xxl-3 col-xl-3 col-lg-8 col-md-8 col-sm-11 col-11 d-flex flex-row m-2 shadow-sm border-info align-self-center"
        style={{ height: "50px" }}
      >
        <span
          onClick={() => setDoSearch((prev) => !prev)}
          className="col-2 searchIcon blue d-flex justify-content-center align-items-center align-content-center position-relative"
        >
          <img
            src="/assets/mag.svg"
            height="25"
            width="25"
            onClick={(e) =>
              searchResults.length > 0
                ? (window.location = `${window.location.origin}/products/${e.target.value}`)
                : null
            }
          />
        </span>
        <input
          className="searchInput col"
          placeholder={
            lang === "he" ? "מה אתם מחפשים לקנות?" : "ماذا تريد أن تشتري؟"
          }
          onKeyDown={resetSearch}
          onChange={(e) => setSearchTerms(e.target.value.trim())}
        />
        <div
          className="searchResults col-xxl-3 col-xl-3 col-lg-5 col-md-8 col-sm-10 col-10 bg-light position-absolute searchResult border border-bottom shadow-sm rounded-bottom d-none"
          style={{ maxHeight: "500px", overflowY: "auto" }}
        >
          <Results searchResults={searchResults} onItemClick={handleClick} />
        </div>
      </div>
      <div
        className="col-xxl-3 col-xl-3 col-lg-6 col-md-8 col-sm-10 col-10 d-flex flex-row m-2"
        style={{ height: "50px" }}
      >
        <select className="form-select ms-2 blueBorderLight text-muted shadow-sm selectos">
          <option value="1">קטגוריה ראשית</option>
          {mainCategories.map((el) => {
            let splitted = el.split("&");
            let name = splitted[0];
            let num = splitted[1];
            return <option value={num}>{name}</option>;
          })}
        </select>
        <select className="form-select blueBorderLight text-muted shadow-sm selectos">
          <option value="1">קטגוריה משנית</option>
          {subCategories.map((el) => {
            let splitted = el.split("&");
            let name = splitted[0];
            let num = splitted[1];
            return <option value={num}>{name}</option>;
          })}
        </select>
      </div>
      <div
        className="col-12 bg-light  d-flex flex-row border-bottom shadow-sm p-2"
        style={{ overflowX: "scroll" }}
      >
        {mainCategories.map((el) => {
          let item = el.split("&");
          return (
            <a
              className="col-xxl-1 col-xl-1 col-lg-2 col-md-2 col-sm-5 col-5 border-start border-primary fs-6 listItem pointer text-dark"
              href={`/products/${item[1]}`}
            >
              {item[0]}
            </a>
          );
        })}
      </div>
    </div>
  );
};

export default Nav;
