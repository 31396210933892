import { useCallback, useEffect, useState } from "react";
import axios from "axios";
import SalesReport from "../../components/SalesReport";

const Login = () => {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [submit, setSubmit] = useState(false);
  const [isLogged, setIsLogged] = useState(false);

  useEffect(async () => {
    let inputs = document.querySelectorAll("input");
    if (username.length < 2) {
      return;
    }
    if (password.length < 2) {
      return;
    }
    const req = await axios.post("https://harelserver778.herokuapp.com/login", {
      username,
      password,
    });
    if (!req.data) {
      document.querySelector(".logStatus").classList.remove("d-none");
      console.log("off");
      return;
    }
    document.querySelector(".logStatus").classList.add("d-none");

    setIsLogged(true);
  }, [submit]);
  const LoginBox = useCallback(() => {
    return (
      <>
        <div className="col-xxl-2 col-xl-3 col-5 d-flex flex-column justify-content-center align-items-center border border-light shadow-sm p-2">
          <span className="">שם משתמש</span>
          <input
            className="form-control"
            type="text"
            placeholder="הקלד כאן"
            onInput={(e) => setUsername(e.target.value)}
          />
        </div>
        <div className="col-xxl-2 col-xl-3 col-5 d-flex flex-column justify-content-center align-items-center border border-light shadow-sm p-2 m-3">
          <span className="">סיסמא</span>
          <input
            className="form-control"
            type="password"
            placeholder="הקלד כאן"
            onInput={(e) => setPassword(e.target.value)}
          />
        </div>
        <div className="col-xxl-2 col-xl-3 col-5 d-flex flex-column justify-content-center align-items-center">
          <div
            className="btn col-12 btn-lg btn-primary col-auto p-2"
            onClick={() => setSubmit((prev) => !prev)}
          >
            שלח
          </div>
        </div>
      </>
    );
  }, []);
  const waitForIt = async (value) => {
    if (value.data === "done") {
      return new Promise((resolve) => resolve(true));
    }
  };
  const ActionBar = () => {
    const [text, setText] = useState("");
    const [submit, setSubmit] = useState(false);
    const [updateProducts, setUpdateProducts] = useState(false);
    const [imageID, setImageID] = useState("");
    const [file, setFile] = useState("");
    const [translateFile, setTranslateFile] = useState(false);
    const [translateText, setTranslateText] = useState('עדכון מוצרים ותרגום ');

    useEffect(async () => {
      try {
        if (updateProducts) {
          let req = await axios.get(
            "https://harelserver778.herokuapp.com/secret"
          );
          const isDone = await waitForIt(req);
          if (isDone) {
            setUpdateProducts(false);
            window.sessionStorage.clear();
            setText("המוצרים עודכנו בהצלחה");
          }
        }
      } catch (err) {
        console.log(err);
        setText("שגיאה.");
      }
    }, [updateProducts]);

    useEffect(() => {
      let form = document.querySelector("#uploadForm");

      form.addEventListener("submit", (e) => {
        e.preventDefault();
      });
    }, []);

    useEffect(async () => {
      if (translateFile) {
        let req = await axios.get("https://harelserver778.herokuapp.com/translate");
        let text = req.data;
        setTranslateText(text);
      }
    }, [translateFile]);
    useEffect(async () => {
      let formdata = new FormData();
      formdata.append("id", imageID);
      formdata.append("file", file);

      if (submit) {
        const res = await axios({
          method: "post",
          url: `/upload`,
          data: formdata,
          headers: { "Content-Type": "application/x-www-form-urlencoded" },
        });
        
        setSubmit(false);
        setText(res.data);
      }
    }, [submit]);
    return (
      <div className="col-12 d-flex flex-column justify-content-center align-items-center">
        <div className="col-12 d-flex flex-row flex-wrap justify-content-center align-items-center">
          <div
            className="col-xxl-3 col-xl-5 col-lg-6 col-md-6 col-sm-10 col-10 d-flex flex-row justify-content-center align-items-center border m-2 rounded shadow-sm fs-3 fw-bolder text-white pointer mbtn"
            style={{ minHeight: "300px", backgroundColor: "#2980b9" }}
            onClick={() => setUpdateProducts((prev) => !prev)}
          >
            {updateProducts ? (
              <div class="spinner-border" role="status">
                <span class="visually-hidden">Loading...</span>
              </div>
            ) : (
              "  עדכון המוצרים באתר"
            )}
          </div>
          <div
            className="position-relative col-xxl-3 col-xl-5 col-lg-6 col-md-6 col-sm-10 col-10 d-flex flex-row justify-content-center align-items-center border m-2 rounded shadow-sm fs-3 fw-bolder text-white pointer mbtn"
            style={{ minHeight: "300px", backgroundColor: "#3498db" }}
            onClick={() => setTranslateFile((prev) => !prev)}
          >
            {translateText}
          </div>
          <div
            className="position-relative col-xxl-3 col-xl-5 col-lg-6 col-md-6 col-sm-10 col-10 d-flex flex-row justify-content-center align-items-center border m-2 rounded shadow-sm fs-3 fw-bolder text-white pointer mbtn"
            style={{ minHeight: "300px", backgroundColor: "#3498db" }}
          >
            <form
              enctype="multipart/form-data"
              id="uploadForm"
              onSubmit={(e) => e.preventDefault()}
            >
              <div class="mb-3 bottom-0">
                <label for="formFile" class="form-label fs-5">
                  העלה תמונה למוצר
                </label>
                <input
                  class="form-control"
                  type="file"
                  id="formFile"
                  onChange={(e) => setFile(e.target.files[0])}
                />
                <label for="fileName" class="form-label fs-5">
                  מק"ט תמונה
                </label>
                <input
                  class="form-control"
                  type="text"
                  id="fileName"
                  onInput={(e) => setImageID(e.target.value)}
                />
                <button
                  type="submit"
                  className="btn border border-white col-6 text-white shadow-sm mt-2"
                  onClick={submit ? () => {} : () => setSubmit((prev) => !prev)}
                >
                  {submit ? (
                    <div class="spinner-border" role="status">
                      <span class="visually-hidden">Loading...</span>
                    </div>
                  ) : (
                    "שלח"
                  )}
                </button>
              </div>
            </form>
          </div>
         <SalesReport/>             
        </div>
        <div className=" m-5 fs-1 border col-6 p-2 text-success shadow-sm fw-bold">
          {text}
        </div>
      </div>
    );
  };
  return (
    <div className="col-12 d-flex flex-column justify-content-center align-items-center">
      <span className="logStatus d-none">פרטי ההתחברות שגויים</span>
      {isLogged ? <ActionBar /> : <LoginBox />}
    </div>
  );
};
export default Login;
