import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  prods: [],
  productsFromSearch:[],
  lang:"he"
};

export const prodSlice = createSlice({
  name: "counter",
  initialState,
  reducers: {
    setProducts: (state, action) => {
      state.prods = action.payload;
    },
    setProductsFromSearch: (state, action) => {
      state.productsFromSearch = action.payload;
    },
    setLangauge: (state, action) => {
      state.lang = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const { setProducts,setProductsFromSearch,setLangauge } = prodSlice.actions;

export default prodSlice.reducer;
